import LiistLogo from "../../img/liist-logo-blue.png";
import EmailHelper from "../helper/EmailHelper";

const Farewell = ({ addTiktokMsg, landing }) => {
  return (
    <div className="export-container">
      <div className="export-inner-container">
        <Navigation />
        <div className="non-navigation-content">

          <div className="flex justify-center align-center flex-grow">
            <div className="main-content-container">
              <h1 className="title" style={{ fontFamily: "Recoleta", fontSize: landing ? "3.4rem" : "40px" }}>Bye Bye Liist 😢</h1>
              <p className="subtitle">Unfortunately, after 3+ years of working on Liist. The team is moving on. All good things must end. The team is incredibly thankful to everyone who enjoyed using Liist. Until next time...</p>
              <p className="subtitle right-align">- Team Liist</p>
              {addTiktokMsg &&
                <DepricationWarning />
              }
              <LiistIsShutDownAlert />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LiistIsShutDownAlert = () => {
  return (
    <div className="info-alert waiting-too-long" style={{ "marginTop": "14px" }}>
      <div className="info-icon"></div>
      <div className="info-text">
        <p>Liist is already shut down and the team no longer active, exporting data is no longer possible. Thank you for your understanding.</p>
      </div>
    </div>
  )
}

const DepricationWarning = () => {
  return (
    <div className="info-alert data-deletion-alert">
      <div className="info-icon"></div>
      <div className="info-text">
        <p>*Liist links won't be supported going forward and will no longer appear on videos.</p>
      </div>
    </div>
  )
}


const FarewellNotification = () => {
  return (
    <div className="info-alert waiting-too-long">
      <div className="info-icon"></div>
      <div className="info-text">
        <p>Want to send us a farewell message? Send us a message 💌 <span className="bold pointer" onClick={EmailHelper.sendMailFarewell}>hello@liist.com</span></p>
      </div>
    </div>
  )
}

const Navigation = () => {
  return (
    <div className="navigation-container">
      <div className="logo-container">
        <img src={LiistLogo} />
      </div>
      <div className="profile-container"></div>
    </div>
  )
}

export default Farewell;
