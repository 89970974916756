import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LiistLogo from "../../../img/liist-logo-blue.png";
import EmailHelper from "../../helper/EmailHelper";

const Export = () => {
  const [requestMyDataBttnLoading, setRequestMyDataBttnLoading] = useState(false);
  const [pageStatus, setPageStatus] = useState("loading"); // "ready", "in_progress", "success", ("failed")
  const [requestDate, setRequestDate] = useState(null);
  const [deliveredAt, setDeliveredAt] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [files, setFiles] = useState([]);

  const { userId } = useParams();
  const targetUrl = `https://liist.com/export/${userId}`;

  useEffect(() => {
    loadData(userId);
  }, []);

  const loadData = async (userId) => {
    try {
      const url = `https://us-central1-liist-prod.cloudfunctions.net/dataExport?userId=${userId}&action=get`;
      const response = await axios.get(url);
      if (response.data.status === "ready") {
        setPageStatus("ready");
        setUserInfo(response.data.userInfo);
      } else if (response.data.status === "in_progress") {
        if (response.data.exportData.status === "init") {
          setPageStatus("in_progress");
          setRequestDate(new Date(response.data.exportData.createdAt));
          setUserInfo(response.data.exportData.userInfo);
        } else if (response.data.exportData.status === "success") {
          setPageStatus("success");
          setRequestDate(new Date(response.data.exportData.createdAt));
          setDeliveredAt(new Date(response.data.exportData.successDate));
          setUserInfo(response.data.exportData.userInfo);
          setFiles(response.data.exportData.files);
        }
      } else {
        setPageStatus("error");
      }

    } catch (err) {
      alert("500 server error, try again in 30 mins. If it still doesn't work, please contact thomas@liist.com");
      setPageStatus("error");
    }
  }

  const requestMyData = async () => {
    setRequestMyDataBttnLoading(true);
    console.log("requiesting data");
    const url = `https://us-central1-liist-prod.cloudfunctions.net/dataExport?userId=${userId}&action=init`;
    const response = await axios.get(url);
    if (response.data.exportData.status === "init") {
      setPageStatus("in_progress");
      setRequestDate(new Date(response.data.exportData.createdAt));
    }
    setRequestMyDataBttnLoading(false);
  }

  const userFirstName = (userInfo.displayName || "").split(" ")[0];

  const jsonFiles = files.filter(file => file.filetype === "json");
  const csvFiles = files.filter(file => file.filetype === "csv" && file.spots > 0);

  return (
    <div className="export-container">
      <div className="export-inner-container">
        <Navigation userInfo={userInfo} pageStatus={pageStatus} />
        <div className="non-navigation-content">
          {pageStatus === "loading" ? (
            <LoadingAnimationExport />
          ) : (
            <div className="flex justify-center align-center flex-grow">
              <div className="main-content-container">
                {pageStatus === "ready" && (
                  <>
                    <h1 className="title">Hi {userFirstName} 👋</h1>
                    <p className="subtitle">We are winding down Liist 😢. But we worked hard on a solution for you to take your location data with you. If you would like to download your spots click the button below and we will get back to you ASAP.</p>
                    <div className="flex justify-center">
                      <button onClick={requestMyData} className="request-my-data-bttn unset">
                        <div className="flex pos-relative justify-center">
                          {requestMyDataBttnLoading ? (
                            <>
                              <p style={{ opacity: 0 }}>Request My Data</p>
                              <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </>
                          ) : (
                            <>
                              <p>Request My Data</p>
                            </>
                          )}
                        </div>
                      </button>
                    </div>
                    <InfoAlertDatatypes />
                  </>
                )}

                {pageStatus === "in_progress" && (
                  <>
                    <h1 className="title">Success 🎉</h1>
                    <p className="subtitle">
                      <span>We are currently collecting your data. Please bookmark this page and check back in on </span>
                      <span className="check-back-in-date">{`${generateLocaleString(new Date("2022-10-24"))}.`}</span>
                      <br></br><span className="bookmark-url">{`${targetUrl}`}</span>
                    </p>
                    <StatusBox requestDate={requestDate} />
                    <LiistIsShutDownAlert />
                    <DataDeletionAlert />
                    {/* <WaitingTooLongAlert userId={userId} /> */}
                  </>
                )}

                {pageStatus === "success" && (
                  <>
                    <div className="success-container-regular">
                      <h1 className="title">Data Exported 🎉</h1>
                      <p className="subtitle text-center">Your data is ready! Download below.</p>
                      <LiistIsShutDownAlert userId={userId} />
                      <DataDeletionAlert />
                      <a target="_blank" rel="noreferrer" className="unset" href="https://liist.notion.site/How-to-export-your-location-data-3a2813c19be84ad2910305b3485dbfea">
                        <div className="flex justify-center align-center">
                          <p className="link-to-notion-tutorial">🗺️ Learn how to import to google maps</p>
                        </div>
                      </a>
                      <p className="file-title-heading">CSV <span className="file-quantity">({csvFiles.length})</span></p>
                      {csvFiles.map((file, indx) => <FileComponent key={indx} file={file} deliveredAt={deliveredAt} />)}
                      <p className="file-title-heading">JSON <span className="file-quantity">({jsonFiles.length})</span></p>
                      {jsonFiles.map((file, indx) => <FileComponent key={indx} file={file} deliveredAt={deliveredAt} />)}
                      <br />

                    </div>
                    <div className="success-container-too-small-device">
                      <h1 className="title">Please use a desktop device ❌</h1>
                      <p className="subtitle text-center">Your data is ready for download. But you can only download it from a desktop device. Please open this page on your desktop device to proceed.</p>
                      <a className="unset url-to-export" href={`https://liist.com/export/${userId}`} ><p className="url text-center">liist.com/export/{userId}</p></a>
                    </div>
                    <br />
                  </>
                )}

                {pageStatus === "error" && (
                  <>
                    <h1 className="title">Error 👷</h1>
                    <p className="subtitle">Ooops... Something went wrong.</p>
                    <div className="flex justify-center">
                      <button onClick={() => EmailHelper.sendMailExportSupport(userId)} className="request-my-data-bttn unset">
                        <div className="flex pos-relative justify-center">
                          <p>Contact Support</p>
                        </div>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


const LoadingAnimationExport = () => {
  return (
    <div className="loading-text-pageload flex justify-center align-center">
      <div className="lds-ellipsis-2"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}


const FileComponent = ({ file, deliveredAt }) => {
  const { title, downloadUrl, spots, filetype } = file;
  const color = {
    "json": "#A067AB",
    "csv": "#84BD59",
  }[filetype];
  const deliveredAtStr = generateLocaleString(deliveredAt);
  return (
    <div className="file-icon-container">
      <p className={`filetype-text ${filetype}`}>{filetype.toUpperCase()}</p>
      <svg className="file-icon" width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 4C9 1.79086 10.7909 0 13 0H29.3431C30.404 0 31.4214 0.421427 32.1716 1.17157L37 6L40.8284 9.82843C41.5786 10.5786 42 11.596 42 12.6569V23V42C42 44.2091 40.2091 46 38 46H13C10.7909 46 9 44.2091 9 42V4Z" fill="#D9D9D9" />
        <path d="M30 0L32 1L36.5 5.5L41 10L42 12H30V0Z" fill="#A7A7A7" />
        <path d="M30 12H42V24L36 18L30 12Z" fill="#D0D0D0" />
        <rect y="23" width="36" height="20" rx="4" fill={color} />
      </svg>

      <div className="right-section">
        <div className="left-side-of-right-section">
          <p className="file-title text-elipsis">{title}<span className="title-filetype">.{filetype}</span></p>
          <p className="file-metadata text-elipsis"><span className="data-metadata">{deliveredAtStr}</span><span className="dot-seperator">•</span>{spots === 1 ? `1 spot` : `${spots} spots`}</p>
        </div>
        <a href={downloadUrl} download target="_blank" rel="noreferrer" className="unset">
          <div className="download-icon-container">
            <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0.399902 27.6001C0.399902 27.1227 0.589545 26.6649 0.92711 26.3273C1.26468 25.9897 1.72251 25.8001 2.1999 25.8001H23.7999C24.2773 25.8001 24.7351 25.9897 25.0727 26.3273C25.4103 26.6649 25.5999 27.1227 25.5999 27.6001C25.5999 28.0775 25.4103 28.5353 25.0727 28.8729C24.7351 29.2105 24.2773 29.4001 23.7999 29.4001H2.1999C1.72251 29.4001 1.26468 29.2105 0.92711 28.8729C0.589545 28.5353 0.399902 28.0775 0.399902 27.6001ZM6.3273 13.7275C6.66485 13.3901 7.12261 13.2005 7.5999 13.2005C8.0772 13.2005 8.53495 13.3901 8.8725 13.7275L11.1999 16.0549V2.4001C11.1999 1.92271 11.3895 1.46487 11.7271 1.12731C12.0647 0.78974 12.5225 0.600098 12.9999 0.600098C13.4773 0.600098 13.9351 0.78974 14.2727 1.12731C14.6103 1.46487 14.7999 1.92271 14.7999 2.4001V16.0549L17.1273 13.7275C17.2933 13.5556 17.492 13.4185 17.7116 13.3241C17.9312 13.2298 18.1674 13.1801 18.4064 13.178C18.6454 13.176 18.8824 13.2215 19.1036 13.312C19.3248 13.4025 19.5258 13.5362 19.6948 13.7052C19.8638 13.8742 19.9975 14.0752 20.088 14.2964C20.1785 14.5176 20.224 14.7546 20.222 14.9936C20.2199 15.2326 20.1702 15.4688 20.0759 15.6884C19.9816 15.908 19.8444 16.1067 19.6725 16.2727L14.2725 21.6727C13.935 22.0101 13.4772 22.1997 12.9999 22.1997C12.5226 22.1997 12.0649 22.0101 11.7273 21.6727L6.3273 16.2727C5.98986 15.9351 5.80029 15.4774 5.80029 15.0001C5.80029 14.5228 5.98986 14.065 6.3273 13.7275Z" fill="#5A74FF" />
            </svg>
          </div>
        </a>
      </div>
    </div>
  )
}

function generateLocaleString(date) {
  if (!date) return "";
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    hour12: true,
    minute: "2-digit"
  }
  return date.toLocaleString('en-us', options);
}

const getFirstDayOfNextMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

const StatusBox = ({ requestDate }) => {
  const requestDateStr = generateLocaleString(requestDate);
  // const dueDate = new Date(Number(requestDate) + (24 * 60 * 60 * 1000 * 3)); // 3 days later
  const dueDate = new Date("2022-10-24");
  const dueDateStr = generateLocaleString(dueDate);

  return (
    <div className="status-box-container-outer">
      <div className="status-box-container">
        <div className="name">status</div>
        <div className="value"><StatusIndicator color="green" /></div>
        <div className="name">request date</div>
        <div className="value">{requestDateStr}</div>
        <div className="name">due date</div>
        <div className="value">{dueDateStr}</div>
      </div>
    </div>
  )
}

const StatusIndicator = ({ color }) => {
  let text = color === "green" ? "in progress" : "failed";
  return (
    <div className={`status-indicator-container ${color}`}>
      <div className="outer-circle">
        <div className="inner-circle"></div>
      </div>
      <p>{text}</p>
    </div>
  )
}

const WaitingTooLongAlert = ({ userId }) => {
  return (
    <div className="info-alert waiting-too-long">
      <div className="info-icon"></div>
      <div className="info-text">
        <p>In case your due date already passed, you can still try to contact <span className="bold pointer" onClick={() => EmailHelper.sendMailExportSupportDueDateExpired(userId)}>thomas@liist.com</span> </p>
      </div>
    </div>
  )
}

const LiistIsShutDownAlert = () => {
  return (
    <div className="info-alert waiting-too-long">
      <div className="info-icon"></div>
      <div className="info-text">
        <p>Liist is already shut down and the team no longer active, thank you for your understanding.</p>
      </div>
    </div>
  )
}

const DataDeletionAlert = () => {
  return (
    <div className="info-alert data-deletion-alert">
      <div className="info-icon"></div>
      <div className="info-text">
        <p>This data is availible until <span className="bold">October 31, 2022</span>. After this date, it will no longer be availible.</p>
      </div>
    </div>
  )
}

const NeedHelpAlert = ({ userId }) => {
  return (
    <div className="info-alert waiting-too-long">
      <div className="info-icon"></div>
      <div className="info-text">
        <p>Need help with your exports? Contact support at <span className="bold pointer" onClick={() => EmailHelper.sendMailExportSupport(userId)}>thomas@liist.com</span></p>
      </div>
    </div>
  )
}

const InfoAlertDatatypes = () => {
  return (
    <div className="info-alert">
      <div className="info-icon"></div>
      <div className="info-text">
        <p>Your data will be exported as <span className="bold">CSV</span> and <span className="bold">JSON</span> files and can be imported in google My Maps.</p>
      </div>
    </div>
  )
}


const Navigation = ({ userInfo, pageStatus }) => {
  const avatar = userInfo.image;
  const displayName = userInfo.displayName || "noName";
  const email = userInfo.email || "noEmail";
  return (
    <div className="navigation-container">
      <div className="logo-container">
        <img src={LiistLogo} />
      </div>
      {pageStatus !== "error" && pageStatus !== "loading" && (
        <div className="profile-container">
          <div className="left-side">
            <p className="name text-elipsis">{displayName}</p>
            <p className="email text-elipsis">{email}</p>
          </div>
          <div className="right-side">
            <img src={avatar} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Export;
