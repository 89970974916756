import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Export from "./pages/export/Export";
import Farewell from "./pages/Farewell";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCsbusxejPEpOcO7LRs2dX_9GPTVNXpmUk",
  authDomain: "liist-prod.firebaseapp.com",
  databaseURL: "https://liist-prod.firebaseio.com",
  projectId: "liist-prod",
  storageBucket: "liist-prod.appspot.com",
  messagingSenderId: "948085409103",
  appId: "1:948085409103:web:74f8a086ef7fd4d42e313a",
  measurementId: "G-1TXXB2SXTH"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const App = () => {
  useEffect(() => {
    const currentUrl = window.location.href;
    if (!currentUrl.includes("/tiktokjump/")) {
      document.title = 'Liist';
    }
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Farewell landing={true} />
        </Route>
        <Route exact path="/list/:slug">
          <Farewell />
        </Route>

        {/* EXPORT */}
        <Route exact path="/export/:userId">
          <Farewell />
        </Route>


        {/* JUMP V2 */}
        {/* JUMP V2 */}
        {/* JUMP V2 */}
        {/* JUMP V2 */}
        <Route exact path="/jump/create">
          <Farewell addTiktokMsg={true} />
        </Route>
        <Route exact path="/jump/view">
          <Farewell addTiktokMsg={true} />
        </Route>
        {/* VIEWER successfully saved jump */}
        <Route exact path="/jump/success">
          <Farewell addTiktokMsg={true} />
        </Route>
        {/* VIEWER saving jump failed */}
        <Route exact path="/jump/error">
          <Farewell addTiktokMsg={true} />
        </Route>

        <Route exact path="/tiktokjumpload">
          <Farewell addTiktokMsg={true} />
        </Route>
        <Route exact path="/tiktokjump/create">
          <Farewell addTiktokMsg={true} />
        </Route>
        <Route exact path="/tiktokjump/view/:token">
          <Farewell addTiktokMsg={true} />
        </Route>
        <Route exact path="/404">
          <Farewell />
        </Route>
        <Route path="*">
          <Farewell />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
